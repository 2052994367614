import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  const currentQuery = router.currentRoute.query

  // Table Handlers
  const tableColumns = [
    { key: 'user' },
    { key: 'email', sortable: true },
    { key: 'role' },
    { key: 'actions' },
  ]
  const perPage = ref(+currentQuery.perPage || 10)
  const totalUsers = ref(0)
  const currentPage = ref(+currentQuery.currentPage || 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(currentQuery.searchQuery || '')
  const sortBy = ref('email')
  const isSortDirDesc = ref(currentQuery.isSortDirDesc === 'true' || false)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    const query = {}
    if (currentPage.value) {
      query.currentPage = currentPage.value
    }
    if (perPage.value) {
      query.perPage = perPage.value
    }
    if (searchQuery.value) {
      query.searchQuery = searchQuery.value
    }
    query.isSortDirDesc = isSortDirDesc.value ? 'true' : 'false'
    router.push({ name: router.currentRouteName, query })

    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([searchQuery, isSortDirDesc], () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else {
      refetchData()
    }
  })

  const fetchUsers = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        ordering,
      })
      .then(response => {
        let users = response.data.results

        users = users.map(user => ({
          ...user,
          role: user.groups.map(group => group.name).join(', '),
        }))
        callback(users)
        totalUsers.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'supplier-admin' || role === 'client-admin' || role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'admin') return 'ServerIcon'
    if (role === 'supplier-admin' || role === 'client-admin') return 'SettingsIcon'
    return 'UserIcon'
  }

  const resolveUserRoleText = role => {
    if (role === 'admin') return 'Super User'
    if (role === 'supplier-admin' || role === 'client-admin') return 'Admin'
    return ''
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserRoleText,
    refetchData,

    // Extra Filters
    roleFilter,
  }
}
