import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/users/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        const {
          email, firstName, lastName, jobDescription,
        } = userData
        const submitData = {
          email,
          first_name: firstName,
          last_name: lastName,
          job_description: jobDescription,
        }
        axios
          .post('/api/v1/users/', submitData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
